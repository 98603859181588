import styled from "styled-components";
import "../i18n";
import React, { useState, useEffect, useCallback } from "react";
import Icon from "@mdi/react";
import { mdiChartLine, mdiAccountMultiple } from "@mdi/js";
import "react-circular-progressbar/dist/styles.css";
import i18next, { t, changeLanguage } from "i18next";
import OverallStandings from "../types/OverallStandings";
import * as GoalsRestClient from "../services/GoalsRestClient";
import OwnContribution from "../types/OwnContribution";
import Ranking from "../types/Ranking";
import RankingEntry from "../types/RankingEntry";
import { useSearchParams } from "react-router-dom";
import logo from "../assets/img/transgourmetLogo.png";

export const COLOR = "#e20026";

interface MicrositeProps {}

const Microsite = (props: MicrositeProps) => {
  let [searchParams] = useSearchParams();

  const [teamId, setTeamId] = useState("");
  const [teamIds, setTeamIds] = useState("");
  const [userId, setUserId] = useState("");

  const [overallStandings, setOverallStandings] = useState<OverallStandings>();
  const [ownContribution, setOwnContribution] = useState<OwnContribution>();

  const [teamsRankingListItems, setTeamsRankingListItems] = useState<
    Array<object>
  >([]);
  const [teamsAfterTenRankingListItems, setTeamsAfterTenRankingListItems] =
    useState<Array<object>>([]);
  const [ownTeamListItem, setOwnTeamListItem] = useState<object>();
  const [showAllTeams, setShowAllTeams] = useState(false);

  const loadOverallStandings = useCallback(async () => {
    let overallStandings: OverallStandings =
      await GoalsRestClient.getOverallStandings(i18next.language);
    setOverallStandings(overallStandings);
  }, []);

  const loadOwnContribution = useCallback(async () => {
    let ownContribution: OwnContribution =
      await GoalsRestClient.getOwnContribution(userId, teamId, teamIds);
    setOwnContribution(ownContribution);
  }, [userId, teamId, teamIds]);

  const setRankingList = useCallback(
    (
      sourceArray: Array<RankingEntry>,
      setFunction: (
        value: ((prevState: Array<object>) => Array<object>) | Array<object>
      ) => void,
      highlightIds: string[],
      metric: string,
      scoreWidth: number
    ): void => {
      const itemsList = [];
      for (let i = 0; i < sourceArray.length; i++) {
        const isHighlighted =
          highlightIds && highlightIds.includes(sourceArray[i].id);
        itemsList.push(
          <RankingListItem
            key={"rankingListItem" + sourceArray[i].id}
            backgroundColor={isHighlighted ? "#BBBBBB" : "#FFFFFF"}
          >
            <TextContainer width={10}>
              <Text
                fontSize={14}
                fontColor={"#000000"}
                fontWeight={isHighlighted ? "bold" : "normal"}
              >
                {sourceArray[i].position}.
              </Text>
            </TextContainer>
            <TextContainer width={85 - scoreWidth} textAlign={"left"}>
              <Text
                textAlign={"left"}
                fontSize={14}
                fontColor={"#000000"}
                fontWeight={isHighlighted ? "bold" : "normal"}
              >
                {sourceArray[i].name}
              </Text>
            </TextContainer>
            <TextContainer width={scoreWidth} textAlign={"right"}>
              <Text
                fontSize={14}
                fontColor={"#000000"}
                fontWeight={isHighlighted ? "bold" : "normal"}
                textAlign={"right"}
                paddingRight={10}
              >
                {Number(sourceArray[i].score * (overallStandings?.donation?.conversionFactor || 1)).toFixed(
                  2
                )}{" "}
                {metric}
              </Text>
            </TextContainer>
          </RankingListItem>
        );
        if (i < sourceArray.length) {
          itemsList.push(
            <HorizontalRule key={"horizontalRule" + sourceArray[i].id} />
          );
        }
      }
      setFunction(itemsList);
    },
    [overallStandings?.donation?.conversionFactor]
  );

  const loadRankingListItems = useCallback(
    async (teamId: string | null, teamIds: string | null) => {
      let teamsRanking: Ranking = await GoalsRestClient.getTeamsRanking(
        teamId,
        teamIds
      );
      setRankingList(
        teamsRanking.quarterly.slice(0, 10),
        setTeamsRankingListItems,
        teamsRanking.highlighted,
        t("TEAMS_METRIC"),
        45
      );
      setRankingList(
        teamsRanking.quarterly.slice(10),
        setTeamsAfterTenRankingListItems,
        teamsRanking.highlighted,
        t("TEAMS_METRIC"),
        45
      );
      var ownTeamMonthly = teamsRanking.monthly.filter((team) => {
        return (
          (team.id === teamId || teamIds?.includes(team.id)) &&
          team.position > 10
        );
      });
      if (
        ownTeamMonthly &&
        ownTeamMonthly[0] &&
        ownTeamMonthly[0].position > 10
      ) {
        setRankingList(
          ownTeamMonthly,
          setOwnTeamListItem,
          teamsRanking.highlighted,
          "km / person",
          45
        );
      }
      var ownTeamQuarterly = teamsRanking.quarterly.filter((team) => {
        return (
          (team.id === teamId || teamIds?.includes(team.id)) &&
          team.position > 10
        );
      });
      if (
        ownTeamQuarterly &&
        ownTeamQuarterly[0] &&
        ownTeamQuarterly[0].position > 10
      ) {
        setRankingList(
          ownTeamQuarterly,
          setOwnTeamListItem,
          teamsRanking.highlighted,
          "km / person",
          45
        );
      }
    },
    [
      setRankingList,
      setOwnTeamListItem,
      setTeamsRankingListItems,
      setTeamsAfterTenRankingListItems,
    ]
  );

  useEffect(() => {
    let teamId = searchParams.get("teamId");
    setTeamId(teamId ? teamId : "");
    const teamIds = searchParams.get("teamIds");
    if (typeof teamIds === "string" && teamIds !== "{teamIds}") {
      setTeamIds(teamIds);
    }
    let userId = searchParams.get("userId");
    setUserId(userId ? userId : "");
    let locale = searchParams.get("locale") || navigator.language || "en";
    changeLanguage(locale.substring(0, 2));
    loadRankingListItems(teamId, teamIds);
  }, [searchParams, loadRankingListItems]);

  useEffect(() => {
    loadOverallStandings();
  }, [loadOverallStandings]);

  useEffect(() => {
    loadOwnContribution();
  }, [loadOwnContribution]);

  return (
    <Container>
      <BackgroundHeader id={"nav"} gridArea={"nav"} />
      <VerticalGridContainer style={{ marginTop: -50 }}>
        <CardContainer>
          <HorizontalRule />
          <VerticalContainer justifyContent={"center"}>
            <HorizontalContainer
              height={70}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Icon
                path={mdiChartLine}
                size={1.1}
                horizontal
                vertical
                rotate={180}
                color="black"
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
              />
              <HorizontalTextContainer>
                <Text
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={20}
                  fontWeight={"bold"}
                  fontColor={"#000000"}
                >
                  {Math.round(overallStandings?.current || 0)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
                </Text>
                <Text
                  paddingLeft={2}
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={12}
                  fontWeight={"normal"}
                  fontColor={"#000000"}
                >
                  {" "}
                  {overallStandings?.metricName || t("POINTS")}
                </Text>
              </HorizontalTextContainer>
            </HorizontalContainer>
          </VerticalContainer>
          <HorizontalRule />
          <HorizontalContainer
            height={70}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VerticalContainer
              justifyContent={"center"}
              alignItems={"center"}
              height={60}
              flex={1}
              borderRight={"1px"}
            >
              <Text
                align={"center"}
                fontSize={12}
                fontWeight={"normal"}
                fontColor={COLOR}
              >
                {t("YOUR_CONTRIBUTION")}
              </Text>
              <HorizontalTextContainer>
                <Text
                  align={"center"}
                  alignSelf={"flex-end"}
                  fontSize={20}
                  fontWeight={"bold"}
                  fontColor={COLOR}
                >
                  {ownContribution?.value
                    ? Math.round(ownContribution.value)
                    : 0}
                </Text>
                <Text
                  paddingLeft={2}
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={12}
                  fontWeight={"normal"}
                  fontColor={COLOR}
                >
                  {" "}
                  {overallStandings?.metricName || t("POINTS")}
                </Text>
              </HorizontalTextContainer>
            </VerticalContainer>
            <VerticalRule />
            <VerticalContainer
              justifyContent={"center"}
              alignItems={"center"}
              height={60}
              flex={1}
            >
              <Text
                align={"center"}
                fontSize={12}
                fontWeight={"normal"}
                fontColor={COLOR}
              >
                {t("YOUR_TEAMS_CONTRIBUTION")}
              </Text>
              <HorizontalTextContainer>
                <Text
                  align={"center"}
                  alignSelf={"flex-end"}
                  fontSize={20}
                  fontWeight={"bold"}
                  fontColor={COLOR}
                >
                  {Math.round(ownContribution?.teamValue || 0)}
                </Text>
                <Text
                  paddingLeft={2}
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={12}
                  fontWeight={"normal"}
                  fontColor={COLOR}
                >
                  {" "}
                  {overallStandings?.metricName || t("POINTS")}
                </Text>
              </HorizontalTextContainer>
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalRule />
          <HorizontalContainer height={70} alignItems={"center"}>
            <Icon
              path={mdiAccountMultiple}
              size={1.1}
              horizontal
              vertical
              rotate={180}
              color="black"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "8px",
                paddingTop: 4,
              }}
            />
            <HorizontalTextContainer>
              <Text
                textAlign={"left"}
                alignSelf={"flex-end"}
                align={"center"}
                fontSize={20}
                fontWeight={"bold"}
                fontColor={"#000000"}
              >
                {overallStandings?.teamCount}
              </Text>
              <Text
                paddingLeft={2}
                alignSelf={"flex-end"}
                fontSize={12}
                fontWeight={"normal"}
                fontColor={"#000000"}
              >
                {" "}
                {t("TEAMS")}
              </Text>
            </HorizontalTextContainer>
          </HorizontalContainer>
        </CardContainer>
        <CardContainer style={{ minHeight: 200, justifyContent: "center" }}>
          <VerticalContainer justifyContent={"space-evenly"} minHeight={130}>
            <Text
              align={"center"}
              fontSize={12}
              fontWeight={"bold"}
              fontColor={"#000000"}
            >
              {overallStandings?.donation?.totalContributionText ||
                t("OUR_CURRENT_DONATION")}
            </Text>
            <Text
              align={"center"}
              fontSize={20}
              fontWeight={"bold"}
              fontColor={COLOR}
            >
              {Math.round(Number(overallStandings?.donation?.current || 0))
                .toString()
                .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}{" "}
              {overallStandings?.donation?.unitName || "kg CO2"}
            </Text>
            <HorizontalRule />
            <Text
              align={"center"}
              fontSize={12}
              fontWeight={"bold"}
              fontColor={"#000000"}
            >
              {overallStandings?.donation?.goalText || t("OUR_DONATION_GOAL")}
            </Text>
            <Text
              align={"center"}
              fontSize={20}
              fontWeight={"bold"}
              fontColor={COLOR}
            >
              {Math.round(Number(overallStandings?.donation?.goal || 100000))
                .toString()
                .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}{" "}
              {overallStandings?.donation?.unitName || "kg CO2"}
            </Text>
          </VerticalContainer>
        </CardContainer>
      </VerticalGridContainer>
      <VerticalGridContainer>
        <TableContainer>
          <TitleContainer>
            <Text fontSize={18} fontColor={"#000000"} fontWeight={"bold"}>
              {t("RANKING")}
            </Text>
            <Text fontSize={18} fontColor={COLOR} fontWeight={"bold"}>
              {t("TEAMS")}
            </Text>
          </TitleContainer>
          {teamsRankingListItems}
          {showAllTeams ? teamsAfterTenRankingListItems : ownTeamListItem}
          {showAllTeams ? (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllTeams(false);
              }}
            >
              <Text fontSize={14} fontWeight={"bold"} fontColor={COLOR}>
                {t("SEE_LESS")} ↑
              </Text>
            </RankingListItem>
          ) : null}
          {showAllTeams ? null : (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllTeams(true);
              }}
            >
              <Text fontSize={14} fontWeight={"bold"} fontColor={COLOR}>
                {t("MORE")} ↓
              </Text>
            </RankingListItem>
          )}
        </TableContainer>
      </VerticalGridContainer>
    </Container>
  );
};

type TextContainerProps = {
  width: number;
  backgroundColor?: string;
  textAlign?: string;
};

type ContainerProps = {
  height?: number;
  width?: number;
};

type CardContainerProps = {};

type TableContainerProps = {};

type HorizontalContainerProps = {
  height?: number;
  width?: number;
  justifyContent?: string;
  alignItems?: string;
  backgroundColor?: string;
  paddingLeft?: number;
  marginTop?: number;
};

type VerticalContainerProps = {
  height?: number;
  width?: number;
  minHeight?: number;
  maxHeight?: number;
  maxWidth?: number;
  flex?: number;
  minWidth?: number;
  justifyContent?: string;
  alignItems?: string;
  borderRight?: string;
  backgroundColor?: string;
  paddingLeft?: number;
  margin?: number;
  marginRight?: number;
  marginTop?: number;
  boxShadow?: boolean;
};

type HorizontalRuleProps = {
  width?: number;
};

type TextProps = {
  fontSize?: number;
  fontColor?: string;
  fontWeight?: string;
  lineHeight?: number;
  align?: string;
  backgroundColor?: string;
  alignSelf?: string;
  textAlign?: string;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

type VerticalGridContainerProps = {
  backgroundColor?: string;
};

type BackgroundHeaderProps = {
  gridArea: string;
};

type RankingListItemProps = {
  padding?: number;
  justifyContent?: string;
  backgroundColor?: string;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
`;

const BackgroundHeader = styled.div<BackgroundHeaderProps>`
  background-color: ${COLOR};
  min-height: 130px;
  width: 100%;
  display: flex;
  background-image: url(${logo});
  background-size: 200px;
  background-repeat: no-repeat;
  background-position-y: 10px;
  background-position-x: 10px;
`;

const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  -webkit-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.26);
  box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.26);
`;

const TableContainer = styled.div<TableContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #eeeeee;
  border-radius: 15px;
`;

const HorizontalTextContainer = styled.div`
  display: inline;
  padding-top: 5px;
`;

const Text = styled.span<TextProps>`
  color: ${(props) => props.fontColor};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.lineHeight};
  margin: 0px;
  text-align: ${(props) => props.align};
  background-color: ${(props) => props.backgroundColor};
  align-self: ${(props) => props.alignSelf};
  text-align: ${(props) => props.textAlign};
  padding-bottom: ${(props) => props.paddingBottom}px;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: ${(props) => props.paddingRight}px;
  z-index: 2;
  font-family: "Ubuntu";
`;

const HorizontalContainer = styled.div<HorizontalContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${(props) => props.alignItems};
  background-color: ${(props) => props.backgroundColor};
  padding-left: ${(props) => props.paddingLeft || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  ${(props) => (props.height ? "height: " + props.height + "px;" : "")}
`;

const VerticalContainer = styled.div<VerticalContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  background-color: ${(props) => props.backgroundColor};
  margin-left: ${(props) => props.paddingLeft || 0}px;
  margin-right: ${(props) => props.marginRight || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin: ${(props) => props.margin}px;
  ${(props) =>
    props.minHeight ? "min-height: " + props.minHeight + "px;" : ""}
  ${(props) =>
    props.maxHeight ? "max-height: " + props.maxHeight + "px;" : ""}
  ${(props) => (props.maxWidth ? "max-width: " + props.maxWidth + "px;" : "")}
  ${(props) => (props.height ? "height: " + props.height + "px;" : "")}
  ${(props) => (props.width ? "width: " + props.width + "px;" : "")}
`;

const VerticalGridContainer = styled.div<VerticalGridContainerProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
`;

const HorizontalRule = styled.hr<HorizontalRuleProps>`
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-color: #f0f0f0;
  color: #f0f0f0;
  background-color: #f0f0f0;
  width: ${(props) => (props.width ? props.width + "%" : "100%")};
  height: 1px;
`;

const VerticalRule = styled.div`
  background-color: #f0f0f0;
  height: 100%;
  width: 1px;
`;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #dbdbdb;
  min-height: 10%;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  padding: 8px;
  box-sizing: border-box;
`;

const RankingListItem = styled.div<RankingListItemProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${(props) => props.padding}px;
  justify-content: ${(props) => props.justifyContent};
  width: 100%;
  background-color: ${(props) => props.backgroundColor || "white"};
`;

const TextContainer = styled.p<TextContainerProps>`
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.backgroundColor};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  padding-right: 8px;
`;

export default Microsite;
